import React, { useState } from "react";
import { CheckIcon } from "@heroicons/react/outline";
import {
  user as userAtom,
  cart as cartAtom,
  products as productsAtom,
} from "../../../RecoilConfig";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Item = ({
  id,
  name,
  image,
  description,
  price,
  varient,
  rating,
  isAddedtoCart,
}) => {
  const user = useRecoilValue(userAtom);
  const [cart, setCart] = useRecoilState(cartAtom);
  const [products, setProducts] = useRecoilState(productsAtom);
  const [checkLogin, setcheckLogin] = useState(false);
  const [addToCart, setAddtoCart] = useState(false);

  const navigate = useNavigate();
  const addToBasket = (ID) => {
    // dispatch data to data layer
    if (user) {
      updateCartinDatabase(ID, 1);
    } else {
      setcheckLogin(true);
      setTimeout(() => {
        setcheckLogin(false);
      }, 5000);
    }
  };

  const fetchCartFromDatabase = (cartID) => {
    axios
      .get(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/carts/${cartID}`)
      .then((response) => setCart(response.data.data.cartProducts));
  };

  const updateCartinDatabase = (productID, productQuantity) => {
    console.log(productID);
    console.log(productQuantity);
    axios
      .post(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/carts/${user.cart}`, {
        cartProducts: {
          cartProductId: productID,
          cartProductQty: productQuantity,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setAddtoCart(true);
          fetchCartFromDatabase(user.cart);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="flex flex-col items-center w-full p-4 mt-4 font-sans bg-gray-200 rounded-md md:flex-row md:w-11/12 lg:w-10/12 md:justify-between justify-evenly">
      <img className="object-contain w-32" src={image} alt="" />
      <div className="w-full mt-4 md:m-0 md:w-1/2">
        <h1
          onClick={() => navigate(`items/${id}`)}
          className="text-lg font-semibold cursor-pointer hover:text-gray-700"
        >
          {name}
        </h1>
        <p className="text-sm text-gray-500 md:w-7/12 md:truncate">
          {description}
        </p>
        <h3 className="my-2 text-sm font-semibold text-gray-700">{price}</h3>
        <span className="flex">
          {Array(rating)
            .fill()
            .map((_, i) => {
              return <p key={i}>⭐</p>;
            })}
        </span>
        <button
          className="my-2 border border-[#1C0A00] font-medium text-xs py-1 px-3 rounded-[3px]"
          disabled="disabled"
        >
          Varient: {varient}
        </button>
      </div>
      <div className="flex items-center justify-center w-full p-4 md:ml-0 md:w-1/4 md:flex-col">
        {!isAddedtoCart() && !addToCart ? (
          <>
            <button
              onClick={() => addToBasket(id)}
              className="px-4 md:px-4 py-2 border m-2 whitespace-nowrap border-gray-200 hover:border-2 hover:border-[#CC9544] "
            >
              Add to Cart
            </button>
            <p
              hidden={!checkLogin}
              className={
                "too-small text-red-600 text-center ease-in-out duration-200"
              }
            >
              Please login to start shopping.
            </p>
          </>
        ) : (
          <button className="flex items-center px-4 py-2 m-2 border border-gray-200 md:px-4 whitespace-nowrap">
            Added <CheckIcon className="w-6 h-6 ml-2 text-green-600" />{" "}
          </button>
        )}

        <button className="px-4 py-2 m-2 text-white bg-gray-800 border-2 border-gray-200 md:px-6 whitespace-nowrap hover:border-2 hover:border-gray-800 hover:bg-transparent hover:text-gray-800 ">
          Buy Now
        </button>
      </div>
    </div>
  );
};

export default Item;
