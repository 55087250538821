import React from "react";

const Aboutus = () => {
  return (
    <div className="container w-full md:w-3/4 px-6 m-auto">
      <h1 className="text-5xl font-sans font-semibold text-[#1C0A00] text-center py-6">
        About us
      </h1>
      <p>
        Beasenest is an online agricultural B2B marketplace, We are on a mission
        to help the people that form the backbone of the Indian economy in one
        way or another, we connect buyers to sellers, provide regularly updated
        prices of agricultural raw materials at one place (gone are the days of
        calling each supplier to enquire about the best price) and provide
        information about the different government schemes and policies and how
        to access them, we're in the process of adding agricultural journals and
        best grow practices to help farmers get a better yeild.
      </p>
      <br />
      <p>
        This book is a treatise on the theory of ethics, very popular during the
        Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit
        amet..", comes from a line in section 1.10.32. The standard chunk of
        Lorem Ipsum used since the 1500s is reproduced below for those
        interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et
        Malorum" by Cicero are also reproduced in their exact original form,
        accompanied by English versions from the 1914 translation by H. Rackham.
        Where can I get some? There are many variations of passages of Lorem
        Ipsum available, but the majority have suffered alteration in some form,
        by injected humour, or randomised words which don't look even slightly
        believable. If you are going to use a passage of Lorem Ipsum, you need
        to be sure there isn't anything embarrassing hidden in the middle of
        text. All the Lorem Ipsum generators on the Internet tend to repeat
        predefined chunks as necessary, making this the first true generator on
        the Internet. It uses a dictionary of over 200 Latin words, combined
        with a handful of model sentence structures, to generate Lorem Ipsum
        which looks reasonable. The generated Lorem Ipsum is therefore always
        free from repetition, injected humour, or non-characteristic words etc.
      </p>
    </div>
  );
};

export default Aboutus;
