import React, { useState} from "react";
import axios from "axios";
import Notify from "../../Utilities/ToastNotification/ToastNotification";

const Contact = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [country, setCountry] = useState("")
  const [message, setMessage] = useState("")
  const handleSubmit = async () => {
    const res = await axios.post("https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/contact",{
      name:name,
      email:email,
      companyName: companyName,
      country: country,
      message: message
    })
    // console.log(res)
    Notify(res.data.message)
  }
  return (
    <div className="  w-full m-auto px-2">
      <div className="w-full flex items-center justify-center my-12  ">
        <div className="bg-gray-200 absolute top-40  dark:bg-gray-800 shadow rounded py-12 lg:px-28 px-8">
          <p className="md:text-3xl text-xl font-bold leading-7 text-center text-gray-700 dark:text-white">
            Let's chat and get a quote!
          </p>
          <div className="md:flex items-center mt-12">
            <div className="md:w-72 flex flex-col">
              <label className="text-base font-semibold leading-none text-gray-800 dark:text-white">
                Name
              </label>
              <input
                tabIndex="0"
                type="name"
                className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                placeholder="Please input name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
              <label className="text-base font-semibold leading-none text-gray-800 dark:text-white">
                Email Address
              </label>
              <input
                tabIndex="0"
                type="name"
                className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                placeholder="Please input email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="md:flex items-center mt-8">
            <div className="md:w-72 flex flex-col">
              <label className="text-base font-semibold leading-none text-gray-800 dark:text-white">
                Company name
              </label>
              <input
                tabIndex="0"
                type="name"
                className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                placeholder="Please input company name"
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
              <label className="text-base font-semibold leading-none text-gray-800 dark:text-white">
                Country
              </label>
              <input
                tabIndex="0"
                type="name"
                className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                placeholder="Please input country name"
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div className="w-full flex flex-col mt-8">
              <label className="text-base font-semibold leading-none text-gray-800 dark:text-white">
                Message
              </label>
              <textarea
                tabIndex="0"
                placeholder="Leave a Message"
                type="name"
                className="h-36 text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100 resize-none"
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
          </div>
          <p className="too-small">
            By clicking submit you agree to our terms of service, privacy policy
            and how we use data as stated
          </p>
          <div className="flex items-center justify-center w-full">
            <button className="btn-invt" onClick={handleSubmit}>SUBMIT</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
