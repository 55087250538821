import React from "react";

const Items = ({ description, name, image, stock, price }) => {
  return (
    <div className="flex flex-col w-10/12 lg:w-fu mx-auto md:flex-row p-4 bg-gray-100 mt-4 font-sans rounded-md items-center md:justify-between justify-evenly">
      <img className="w-32 object-contain" src={image} alt="" />
      <div className=" w-full mt-4 md:m-0  md:w-1/2">
        <h1 className="font-semibold text-lg ">{name}</h1>
        <p className="text-sm md:w-7/12 md:truncate text-gray-500">
          {description}
        </p>
        <h3 className="text-sm text-gray-700 font-semibold my-2">{price}</h3>
        <button
          className="my-2 border border-[#1C0A00] font-medium text-xs py-1 px-3 rounded-[3px]"
          disabled="disabled"
        >
          Stock: {stock}
        </button>
      </div>
    </div>
  );
};

export default Items;
