import React, { useState } from "react";
import { user as userAtom, cart as cartAtom } from "../../../RecoilConfig";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "axios";
import Notify from "../../../Utilities/ToastNotification/ToastNotification";
import { useEffect } from "react";

const CheckOutProduct = ({
  id,
  name,
  image,
  description,
  price,
  varient,
  rating,
  quantity,
}) => {
  const [cart, setCart] = useRecoilState(cartAtom);
  const user = useRecoilValue(userAtom);
  const [Quantity, setQuantity] = useState(1);
  const [reload,setReload] = useState(false)

  
  const removeFromBasket = (indexToRemove) => {
    
    // dispatch data to data layer
    let tempCart = [...cart];
    const index = tempCart.findIndex(
      (basketItem) => basketItem.cartProductId._id === indexToRemove
    );
    if (index >= 0) {
      removeItemFromCartInDatabase(tempCart[index]);
      tempCart.splice(index, 1);
    } else {
      console.warn(
        `cant remove product (id: ${indexToRemove.id}) as it is not in the basket!`
      );
    }
    setCart(tempCart);
  };

  useEffect(() => {},[reload])

  const removeItemFromCartInDatabase = (productStatement) => {
    axios
      .put(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/carts/${user?.cart}/remove`, {
        cartProducts: {
          cartProductId: productStatement.cartProductId?._id,
          cartProductQty: productStatement.cartProductQty,
          cartTotalPrice: productStatement.cartTotalPrice,
          productSellerId: productStatement.productSellerId,
        },
      })
      .then((response) => Notify(response.data.message))
      .catch((err) => console.log(err));
  };

  const fetchCartFromDatabase = (cartID) => {
    axios
      .get(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/carts/${user.cart}`)
      .then((response) => setCart(response.data.data.cartProducts));
    
    setReload(prev => !prev)
  };

  const updateQuantityHandler = (idOftheItemtoBeUpdated, quantityToSet) => {
    axios
      .put(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/carts/${user.cart}`, {
        productId: idOftheItemtoBeUpdated,
        quantity: +quantityToSet,
      })
      .then((response) => {
        Notify(response.data.message);
        fetchCartFromDatabase(cart)
      })
      .catch((err) => {
        console.log(err);
        Notify(err.message);
      });
  };

  return (
    <div className="flex flex-col items-center w-full p-4 mt-4 font-sans bg-gray-200 rounded-md md:flex-row md:w-11/12 md:justify-between justify-evenly">
      <img
        className="object-contain w-20 md:w-24 lg:32 md:"
        src={image}
        alt=""
      />
      <div className="w-full mt-4  md:mt-0 md:w-1/2">
        <h1 className="text-lg font-semibold ">{name}</h1>
        <p className="text-sm text-gray-500 md:w-32 lg:w-1/2 md:truncate">
          {description}
        </p>
        <h3 className="my-2 text-sm font-semibold text-gray-700">₹ {price}</h3>
        <span className="flex">
          {Array(rating)
            .fill()
            .map((_, i) => {
              return <p key={i}>⭐</p>;
            })}
        </span>
        <button
          className="my-2 border border-[#1C0A00] font-medium text-xs py-1 px-3 rounded-[3px]"
          disabled="disabled"
        >
          Varient: {varient}
        </button>
        <p>Quantity: {quantity}</p>
      </div>
      <div className="flex items-center mt-5 space-x-2 text-center md:flex-col justify-evenly md:space-x-0">
        <div>
          <p className="p-2 text-sm text-gray-700">Quantity</p>
          <span className="relative inline">
            <input
              onChange={(e) => {
                setQuantity(e.target.value);
              }}
              className="h-8 px-2 font-sans bg-gray-100 border border-gray-700 rounded-sm w-28 focus:outline-none "
              type="number"
              name=""
              min={1}
              defaultValue={quantity}
            />
            <button
              onClick={() => updateQuantityHandler(id, Quantity)}
              className="absolute right-0 px-2 py-1 font-normal text-white bg-gray-700 rounded-r-sm hover:font-medium"
            >
              Set
            </button>
          </span>
        </div>
        <br />
        <button
          onClick={() => removeFromBasket(id)}
          className="px-4 mt-0 border-2 border-transparent rounded-sm whitespace-nowrap md:mt-5 hover:border-gray-600 "
        >
          Remove
        </button>
      </div>
    </div>
  );
};

export default CheckOutProduct;
