import { useState } from "react"; // import state
import { NavLink, useNavigate } from "react-router-dom";
import { user as userAtom, cart as cartAtom } from "../../RecoilConfig";
import { useRecoilState, useRecoilValue } from "recoil";

export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false); // initiate isNavOpen state with false
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const cart = useRecoilValue(cartAtom);
  const isUser = useRecoilValue(userAtom);
  const [tab, setTab] = useState(null);

  const loginHandler = () => {
    setIsNavOpen(false);
    if (user) {
      setUser(null);
      localStorage.removeItem("user");
      localStorage.removeItem("cart");
      navigate("/", { replace: true });
    }

    navigate("/registeration");
  };
  return (
    <div className="flex items-center font-medium justify-between bg-white shadow-sm border-b border-gray-400 py-6 ">
      <NavLink to="/">
        <h2 className="px-3 font-bold text-lg lg:text-3xl ml-5 hover:scale-105">
          Beasenest
        </h2>
      </NavLink>

      <nav className="text-[#1C0A00]">
        <section className="MOBILE-MENU flex lg:hidden">
          <div
            className="HAMBURGER-ICON space-y-2 mr-5"
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
          </div>

          <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
            <div
              className="CROSS-ICON absolute top-0 right-0 px-8 py-8"
              onClick={() => setIsNavOpen(false)}
            >
              <svg
                className="h-8 w-8 text-gray-600"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <ul className="MENU-LINK-MOBILE-OPEN flex flex-col z-50 items-center justify-between min-h-[250px]">
              <li className="border-b border-gray-400 my-8 uppercase">
                <button
                  onClick={() => {
                    navigate("/subcategory/1");
                    setIsNavOpen(false);
                  }}
                  className="text-lg font-medium"
                >
                  Products
                </button>
              </li>
              <li className="border-b border-gray-400 my-8 uppercase">
                <button
                  onClick={() => {
                    navigate("/about");
                    setIsNavOpen(false);
                  }}
                  className="text-lg font-medium"
                >
                  About Us
                </button>
              </li>
              <li className="border-b border-gray-400 my-8 uppercase">
                <button
                  onClick={() => {
                    navigate("/contact");
                    setIsNavOpen(false);
                  }}
                  className="text-lg font-medium"
                >
                  Contact
                </button>
              </li>
              {isUser ? (
                <li className="border-b border-gray-400 my-8 uppercase">
                  {user.userType == "customer" ? (
                    <span
                      onClick={() => {
                        navigate("/cart");
                        setIsNavOpen(false);
                      }}
                      className="flex"
                    >
                      <button className="text-lg font-medium lg:hidden">
                        Cart
                      </button>
                      <p className=" ml-5 text-red-600">({cart.length})</p>
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        navigate("/dashboard");
                        setIsNavOpen(false);
                      }}
                      className="flex"
                    >
                      <button className="text-lg font-medium lg:hidden">
                        Dashboard
                      </button>
                    </span>
                  )}
                </li>
              ) : null}
              <li className="border-b border-gray-400 my-8 uppercase">
                <button onClick={loginHandler} className="text-lg font-medium">
                  {isUser ? "LogOut" : "Login"}
                </button>
              </li>
            </ul>
          </div>
        </section>

        <ul className="DESKTOP-MENU hidden items-center text-gray-700 space-x-8 mr-5 lg:flex">
          <li
            className={`${
              tab === "products" ? "border-b-2 border-gray-800" : ""
            }`}
            onClick={() => setTab("products")}
          >
            <button
              onClick={() => navigate("/products")}
              className="text-lg hover:text-[#0f0601] "
            >
              Products
            </button>
          </li>
          <li
            className={`${tab === "about" ? "border-b-2 border-gray-800" : ""}`}
            onClick={() => setTab("about")}
          >
            <button
              onClick={() => navigate("/about")}
              className="text-lg hover:text-[#0f0601] "
            >
              About Us
            </button>
          </li>
          <li
            className={`${
              tab === "contact" ? "border-b-2 border-gray-800" : ""
            }`}
            onClick={() => setTab("contact")}
          >
            <button
              onClick={() => navigate("/contact")}
              className="text-lg hover:text-[#0f0601]"
            >
              Contact
            </button>
          </li>
          {isUser ? (
            <li
              className={`${
                tab === "access" ? "border-b-2 border-gray-800" : ""
              }`}
              onClick={() => setTab("access")}
            >
              {user.userType === "customer" ? (
                <div className="relative">
                  <button
                    onClick={() => navigate("/cart")}
                    className={`text-lg hover:text-[#0f0601] `}
                  >
                    Cart
                  </button>
                  <p className="h-4 w-4 text-center text-xs absolute -top-3 -right-3 bg-red-600 text-white medium rounded-full">
                    {cart.length}
                  </p>
                </div>
              ) : (
                <div className="relative">
                  <button
                    onClick={() => navigate("/dashboard")}
                    className=" text-lg hover:text-[#0f0601] "
                  >
                    Dashboard
                  </button>
                </div>
              )}
            </li>
          ) : null}
          <li>
            <button onClick={loginHandler} className="btn-invt mt-0">
              {isUser ? "LogOut" : "Login"}
            </button>
          </li>
        </ul>
      </nav>

      <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: white;
        z-index: 100;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        overflow:hidden;
      }
    `}</style>
    </div>
  );
}
