import React, { useState } from "react";

import OTPForm from "./OTPForm/OTPform";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import OTPResponse from "./OTPResponse/OTPResponse";
import ToastNotification from "../../../Utilities/ToastNotification/ToastNotification"

const OTP = ({ userCredentials, userType, ForgetPassword }) => {
  const navigate = useNavigate();
  const [showPhoneNumber, setshowPhoneNumber] = useState(true);
  const [showOtp, setshowOtp] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [OTPverified, setOTPverified] = useState(null);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);

  const [password, setpassword] = useState(null);
  const [Confirmpassword, setConfirmpassword] = useState(null);

  const phoneNumberHandler = () => {
    if (ForgetPassword) {
      handleForgotPassword(phoneNumber);
    } else submitUserCredentials(phoneNumber);
  };

  const handleForgotPassword = (phoneNo) => {
    axios
      .post(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/auth/${userType}/send-otp`, { phoneNo: phoneNo })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setshowPhoneNumber(false);
          setshowOtp((e) => !e);
        }
      })
      .catch((err) => alert("An error occured"));
  };

  const OTPHandler = (verifiedMessage) => {
    setshowOtp(false);
    if (verifiedMessage === "SUCCESS") {
      if (ForgetPassword) {
        setShowChangePasswordForm(true);
      } else {
        setOTPverified("SUCCESS");
        setTimeout(() => {
          navigate("/");
        }, 5000);
      }
    } else {
      setOTPverified("FAILED");
    }
  };

  const submitUserCredentials = (phoneNo) => {
    const body = { ...userCredentials, phoneNo: phoneNo };
    axios
      .post(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/auth/${userType}/register`, body)
      .then((response) => {
        if (response.status === 200) {
          axios
            .post(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/auth/${userType}/send-otp`, { phoneNo: phoneNo })
            .then((res) => {
              if (res.status === 200) {
                console.log(response);
                setshowPhoneNumber(false);
                setshowOtp((e) => !e);
              }
            })
            .catch((err) => alert("An error occured"));
        }
      })
      .catch((err) => console.log(err));
  };

  const updatePasswordHandler = (e) => {
    e.preventDefault();
    axios
      .put(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/${userType}/change-password`, {
        phoneNo: phoneNumber,
        password: password,
        confirmPassword: Confirmpassword,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setShowChangePasswordForm(false);
          setOTPverified("SUCCESS");
          setTimeout(() => {
            navigate("/registeration");
          }, 5000);
        } else {
          setOTPverified("FAILED");
        }
      }).catch(err => console.log(err));
  };
  return (
    <div>
      {showPhoneNumber ? (
        <div className="flex flex-col items-center justify-center w-full h-full py-6 text-center">
          <h3 className="text-[#1C0A00] font-sans text-sm font-medium p-2">
            Please Enter your phone number..
          </h3>
          <p className="p-2 mb-3 font-sans text-xs font-normal text-gray-400">
            <strong>Note:</strong>
            OTP Verification will be done using this phone number make sure its
            correct.
          </p>
          <input
            className="w-full px-4 py-2 font-sans font-medium rounded-md bg-slate-200"
            type="tel"
            name=""
            id=""
            maxLength={10}
            minLength={10}
            onInput={(e) => setPhoneNumber(e.target.value)}
          />
          <button
            className="btn-invt"
            onClick={() => phoneNumberHandler()}
            type="submit"
          >
            Submit
          </button>
        </div>
      ) : null}
      {showOtp ? (
        <OTPForm submit={OTPHandler} phonenumber={phoneNumber} />
      ) : null}
      {OTPverified === "SUCCESS" ? (
        <OTPResponse
          message={"SUCCESS"}
          description={`${
            ForgetPassword
              ? "The Password has been changed Successfully!"
              : "The Phone number has been verified successfully"
          }`}
        />
      ) : null}
      {OTPverified === "FAILED" ? (
        <OTPResponse
          message={"FAILED"}
          description="We couldn't verify your OTP."
        />
      ) : null}
      {showChangePasswordForm ? (
        <div>
          <form className="py-10 text-left">
            <p className="font-medium text-sm text-[#1C0A00] mt-4 py-1  mb-3">
              Choose a Password
            </p>
            <input
              className="h-10 p-3 font-sans bg-transparent border-b-2 rounded-sm border-b-gray-600 focus:outline-none "
              type="password"
              name="password"
              onChange={(e) => setpassword(e.target.value)}
            />
            <p className="font-medium text-sm text-[#1C0A00] mt-4 py-1  mb-3">
              Confirm Password
            </p>
            <input
              className="h-10 p-3 font-sans bg-transparent border-b-2 rounded-sm border-b-gray-600 focus:outline-none "
              type="password"
              name="confirm-password"
              onChange={(e) => setConfirmpassword(e.target.value)}
            />
            <button
              onClick={(e) => updatePasswordHandler(e)}
              className="flex justify-center mx-auto text-center btn-invt"
            >
              Update
            </button>
          </form>
        </div>
      ) : null}
    </div>
  );
};

export default OTP;
