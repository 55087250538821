import {
  ArchiveIcon,
  BookOpenIcon,
  BriefcaseIcon,
  ChartBarIcon,
  ChatAlt2Icon,
  IdentificationIcon,
} from "@heroicons/react/outline";
import { useState } from "react";
import Dashboard from "./Dashboard/Dashboard";
import Account from "./Account/Account";
import Analytics from "./Analytics/Analytics";
import Products from "./Products/Products";
import Orders from "./Orders/Orders"
import { user as UserAtom } from "../../RecoilConfig";
import { useRecoilValue } from "recoil";

const SellerDashboard = () => {
  const user = useRecoilValue(UserAtom)
  const [Tab, setTab] = useState("dashboard");
  return (
    <div className="flex h-full flex-col lg:flex-row">
      <div className="h-24 w-full lg:h-full lg:w-2/12 bg-gray-100 flex flex-row lg:flex-col items-center">
        <span className="sm:w-20 flex items-center justify-center h-32 border-b-2">
          <img
            className=" m-2 rounded-full h-14 w-14"
            src="https://iconape.com/wp-content/png_logo_vector/avatar-4.png"
            alt="avatar"
          />
          <p className="hidden lg:block">{user.name}</p>
        </span>
        <ul className="flex lg:flex-col lg:h-80 px-7 w-full justify-evenly items-center">
          <li onClick={() => setTab("dashboard")} className="hover:font-semibold cursor-pointer">
            <button className="hidden lg:block">
              Dashboard
            </button>
            <BookOpenIcon className="dashboard-navigation" />
          </li>
          <li onClick={() => setTab("account")} className="hover:font-semibold cursor-pointer">
            <button className="hidden lg:block">
              Account
            </button>
            <IdentificationIcon className="dashboard-navigation" />
          </li>
          <li onClick={() => setTab("analytics")} className="hover:font-semibold cursor-pointer">
            <button className="hidden lg:block">
              Analytics
            </button>
            <ChartBarIcon className="dashboard-navigation" />
          </li>
          <li onClick={() => setTab("orders")} className="hover:font-semibold cursor-pointer">
            <button className="hidden lg:block">
              Orders
            </button>
            <ArchiveIcon className="dashboard-navigation" />
          </li>
          <li onClick={() => setTab("products")} className="hover:font-semibold cursor-pointer">
            <button className="hidden lg:block">
              Products
            </button>
            <BriefcaseIcon className="dashboard-navigation" />
          </li>
        </ul>
      </div>
      <div className="w-full lg:w-10/12 mx-auto">
        {Tab === "dashboard" ? (<Dashboard user={user} />) : null}
        {Tab === "account" ? (<Account />) : null}
        {Tab === "analytics" ? (<Analytics />) : null}
        {Tab === "orders" ? (<Orders />) : null}
        {Tab === "products" ? (<Products />) : null}
      </div>
    </div>
  );
};

export default SellerDashboard;
