import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Seller = () => {
  const [Sellername, setSellername] = useState(null);
  const [SellerEmail, setSellerEmail] = useState(null);
  const [PANNumber, setPANNumber] = useState(null);
  const [VoterIDCard, setVoterIDCard] = useState(null);

  const [AadharNumber, setAadharNumber] = useState(null);
  const [CompanyName, setCompanyName] = useState(null);
  const [GSTIn, setGSTIn] = useState(null);
  const [BankAccountNumber, setBankAccountNumber] = useState(null);
  const [TradeLicenseNumber, setTradeLicenseNumber] = useState(null);
  const [password, setpassword] = useState();
  const [Confirmpassword, setConfirmpassword] = useState();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const submitSellerInfo = () => {
    let data = {
      name: Sellername,
      companyName: CompanyName,
      email: SellerEmail,
      PAN: PANNumber,
      Aadhar: AadharNumber,
      GSTIN: GSTIn,
      tradeLicense: TradeLicenseNumber,
      password: password,
      confirmPassword: Confirmpassword,
      address: {
        officeNumber: "23",
        floor: "2",
        landmark: "hai hi nhi",
        latitude: "123.34444",
        longitude: "232433.243",
      },
    };
    navigate(`/registeration/${Sellername}`, { state: data });
  };

  const isFormValid = () => {
    return (
      Sellername &&
      PANNumber &&
      VoterIDCard &&
      AadharNumber &&
      GSTIn &&
      BankAccountNumber &&
      TradeLicenseNumber &&
      CompanyName &&
      password &&
      Confirmpassword &&
      SellerEmail
    );
  };

  const passwordLengthShouldbeGreatorthenfive = () => {
    return password?.length >= 5;
  };
  const doesPasswordMatch = () => {
    return password === Confirmpassword;
  };

  return (
    <div className="w-10/12 md:w-3/4 bg-gray-200 text-[#1C0A00] h-3/4 overflow-y-scroll text-center  m-auto mt-8 border shadow-sm border-[#868381] rounded-md">
      <h1 className="text-center my-6 text-[#1C0A00] text-4xl mx-3 pb-2 border border-b-[#1C0A00] md:mx-10 ">
        Seller
      </h1>
      <form action="" onSubmit={submitSellerInfo}>
        <label className="text-sm text-red-700" htmlFor="">
          <strong>Note:</strong> All fields are necessary.
        </label>
        <div className="flex flex-col md:flex-row justify-center m-auto items-start align-middle">
          <div className="space-y-4 w-full  md:w-1/2 py-4 px-14">
            <input
              onChange={(e) => setSellername(e.target.value)}
              required
              className="inpt-seller"
              placeholder="Name"
              type="text"
              name=""
            />
            <input
              onChange={(e) => setSellerEmail(e.target.value)}
              required
              className="inpt-seller"
              placeholder="Email"
              type="email"
              name=""
            />
            <input
              onChange={(e) => setPANNumber(e.target.value)}
              required
              className="inpt-seller"
              placeholder="PAN Number"
              type="text"
              name=""
            />
            <input
              onChange={(e) => setGSTIn(e.target.value)}
              required
              className="inpt-seller"
              placeholder="GSTIn Number"
              type="text"
              name=""
            />
            <input
              onChange={(e) => setCompanyName(e.target.value)}
              required
              className="inpt-seller"
              placeholder="Company Name"
              type="text"
              name=""
            />
            <div>
              <label className="too-small" htmlFor="">
                Attach all the docuement in a single pdf file.
              </label>
              <input className="inpt-seller" type="file" name="" id="" />
            </div>
          </div>
          <div className="space-y-4 w-full  md:w-1/2 py-3 px-14 ">
            <input
              onChange={(e) => setAadharNumber(e.target.value)}
              required
              className="inpt-seller"
              placeholder="Aadhar Number"
              type="text"
              name=""
            />
            <input
              onChange={(e) => setVoterIDCard(e.target.value)}
              required
              className="inpt-seller"
              placeholder="Voter ID Number"
              type="text"
              name=""
            />
            <input
              onChange={(e) => setTradeLicenseNumber(e.target.value)}
              required
              className="inpt-seller"
              placeholder="Trade License Number"
              type="number"
              name=""
            />
            <input
              onChange={(e) => setBankAccountNumber(e.target.value)}
              required
              className="inpt-seller"
              placeholder="Bank ACC Number"
              type="text"
              name=""
            />
            <span className="relative">
              <input
                onChange={(e) => setpassword(e.target.value)}
                required
                className="inpt-seller"
                placeholder="Enter Password"
                type={`${showPassword ? "text" : "password"}`}
                name=""
              />
              <span
                className="absolute top-1 right-1 cursor-pointer"
                onClick={() => setShowPassword((e) => !e)}
              >
                {showPassword ? (
                  <EyeOffIcon className="h-5 w-5 mx-2 outline-2 outline-gray-500" />
                ) : (
                  <EyeIcon className="h-5 w-5 mx-2 outline-1 outline-gray-500" />
                )}
              </span>
            </span>

            <span className="relative">
              <input
                onChange={(e) => setConfirmpassword(e.target.value)}
                required
                className="inpt-seller"
                placeholder="Confirm Password"
                type={`${showConfirmPassword ? "text" : "password"}`}
                name=""
              />
              <span
                className="absolute top-1 right-1 cursor-pointer"
                onClick={() => setshowConfirmPassword((e) => !e)}
              >
                {showConfirmPassword ? (
                  <EyeOffIcon className="h-5 w-5 mx-2 outline-2 outline-gray-500" />
                ) : (
                  <EyeIcon className="h-5 w-5 mx-2 outline-1 outline-gray-500" />
                )}
              </span>
            </span>
          </div>
        </div>
        <p className="too-small text-red-600" hidden={!isFormValid()}>
          Make sure to fill in all the details.
        </p>
        <p hidden={doesPasswordMatch()} className="too-small text-red-600">
          Password do not match
        </p>
        <p
          hidden={passwordLengthShouldbeGreatorthenfive()}
          className="too-small text-red-600"
        >
          Password length should be greator than Five letters.
        </p>
        <button
          disabled={!isFormValid()}
          className="btn-invt my-5 disabled:grayscale"
          type="submit"
        >
          SignUp
        </button>
      </form>
    </div>
  );
};

export default Seller;
