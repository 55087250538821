import React, { useEffect, useState } from "react";
import Card from "./Card/Card";
import Slider from "../Slider/Slider";
import axios from "axios";

const Categories = () => {
  const [data, setData] = useState([]);
  // const [query, setQuery] = useState("");
  useEffect(() => {
    axios
      .get("https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/categories")
      .then((response) => {
        setData(response.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="w-full">
      <Slider />
      <h1 className="text-center my-12 text-[#1C0A00] text-4xl uppercase  border-b-[#1C0A00] ">
        Category
      </h1>
      {/* <div className="px-20 pt-8 pb-1 text-center align-middle">
        <div class="relative text-gray-600 focus-within:text-gray-400">
          <span class="absolute inset-y-0 left-0 flex items-center pl-2">
            <button type="submit" class="p-1 focus:outline-none focus:shadow-outline">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </button>
          </span>
          <input type="search" name="q" class="py-2 w-full text-sm text-white bg-gray-900 rounded-md pl-16 focus:outline-none focus:bg-white focus:text-gray-900" placeholder="Search Category" autocomplete="off" onChange={event => setQuery(event.target.value)} />
        </div>
      </div> */}
      {/* .filter(element => {
          if (query === '') {
            return element;
          } else if (element.name.toLowerCase().includes(query.toLowerCase())) {
            return element;
          }
        }) */}

      <div className="flex flex-wrap justify-evenly">
        {data?.map((element, i) => {
          return (
            <Card
              key={i}
              name={element.name}
              description={element.description}
              image={element.images[0]}
              subCategories={element.subCategories}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Categories;
