import React, { useEffect, useState } from "react";
import Card from "./Card/Card";

import Loader from "../Loader/Loader";
import Slider from "../Slider/Slider";
import {useLocation } from "react-router-dom";

const SubCategories = () => {
  const {state} = useLocation()
  const [showLoader, setshowLoader] = useState();
  useEffect(() => {
    setshowLoader(true);

    setTimeout(() => {
      setshowLoader(false);
    }, 1000);
  }, []);

  return (
    <>
      <div className={`${showLoader ? "visible" : "hidden"}`}>
        <Loader />
      </div>
      <div className={`"w-full" ${!showLoader? "visible" : "hidden"}`}>
        <Slider />
        <h1 className="text-center my-12 text-[#1C0A00] text-4xl uppercase  border-b-[#1C0A00] ">
          SubCategory
        </h1>
        <div className="flex flex-wrap justify-evenly">
          {state?.map((element) => {
            return (
              <Card
                key={element._id}
                name={element.name}
                description={element.description}
                image={element.images[0]}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SubCategories;
