import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Item from "./Item/Item";
import { products as productsAtom, cart as cartAtom } from "../../RecoilConfig";
import { useRecoilState, useRecoilValue } from "recoil";

const Products = () => {
  const [products, setProducts] = useRecoilState(productsAtom);
  const [sortBy, setsortBy] = useState("price");
  const [query, setQuery] = useState("");
  const cart = useRecoilValue(cartAtom);
  useEffect(() => {
    axios.get(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/products/sort/${sortBy}`).then((response) => {
      setProducts(response.data.data);
    });
  }, [sortBy]);
  let { categoryName } = useParams();

  const isElementinTheCart = (productID) => {
    const index = cart.findIndex((ele) => ele.cartProductId._id === productID);
    return index >= 0;
  };
  const handleChange = async (e) => {
    setQuery(e);
    if (query === '') {
      await axios.get(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/products/sort/${sortBy}`).then((response) => {
        setProducts(response.data.data);
      });
    } else {
      await axios.get('https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/products/sort/price/', { params: { query: query } }).then((response) => {
        setProducts(response.data.data);
      });
    }
  }
  return (
    <div className="flex flex-col w-full lg:flex-row">
      <div className="h-32 text-center bg-gray-200 lg:h-screen lg:w-3/12 lg:sticky lg:left-0 lg:top-20">
        <h2 className="m-auto my-6 font-sans text-lg font-medium text-gray-800">
          Filters
        </h2>
        <div className="flex items-center justify-center">
          <div className="inline-flex" role="group">
            <button
              onClick={() => setsortBy("price")}
              type="button"
              className={`
       
        px-6
        py-2
        border-2 border-gray-700
        ${sortBy === "price"
                  ? "bg-gray-800 text-white"
                  : "text-gray-800 bg-transparent"
                }
        font-medium
        text-xs
        leading-tight
        uppercase
        focus:outline-none focus:ring-0
        transition
        duration-75
        ease-out
        hover:bg-black hover:bg-opacity-5
      `}
            >
              Price
            </button>
            <button
              onClick={() => setsortBy("distance")}
              type="button"
              className={`
     
        px-6
        py-2
        border-2 border-gray-700
        ${sortBy === "distance"
                  ? "bg-gray-800 text-white"
                  : "text-gray-800 bg-transparent"
                }
        font-medium
        text-xs
        leading-tight
        uppercase
        focus:outline-none focus:ring-0
        transition
        duration-75
        ease-out
        hover:bg-black hover:bg-opacity-5
      `}
            >
              Distance
            </button>
            <button
              onClick={() => setsortBy("rating")}
              type="button"
              className={`
     
        px-6
        py-2
        border-2 border-gray-700
        ${sortBy === "rating"
                  ? "bg-gray-800 text-white"
                  : "text-gray-800 bg-transparent"
                }
        font-medium
        text-xs
        leading-tight
        uppercase
        focus:outline-none focus:ring-0
        transition
        duration-75
        ease-out
        hover:bg-black hover:bg-opacity-5
      `}
            >
              Rating
            </button>
          </div>
        </div>
      </div>
      <div className="w-full px-4 mx-auto lg:w-9/12">
        <h1 className="mx-auto pb-2 whitespace-nowrap text-2xl md:text-3xl font-medium text-center mt-8 text-[#1C0A00]">
          {categoryName} Listing
        </h1>
        <div className="px-20 pt-8 pb-1 text-center align-middle">
          <div class="relative text-gray-600 focus-within:text-gray-400">
            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
              <button type="submit" class="p-1 focus:outline-none focus:shadow-outline">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
              </button>
            </span>
            <input type="search" name="q" class="py-2 w-full text-sm text-white bg-gray-900 rounded-md pl-16 focus:outline-none focus:bg-white focus:text-gray-900" placeholder="Search Product" autocomplete="off" onChange={event => handleChange(event.target.value)} />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center mt-6">
          {products.map((element, i) => (
            <Item
              key={element._id}
              id={element._id}
              name={element.name}
              image={element.image}
              description={element.longDescription}
              price={element.price}
              varient={element.varient}
              rating={element.rating}
              isAddedtoCart={() => isElementinTheCart(element._id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Products;
