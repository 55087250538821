import React, { useState } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
const Buyer = ({ onFormSubmit }) => {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const doesPasswordMatch = () => {
    return password ? (password === confirmPassword ? true : false) : true;
  };

  const passwordLengthShouldbeGreatorthenfive = () => {
    return password ? (password?.length >= 5 ? true : false) : true;
  };
  const submitBuyerInfo = (e) => {
    e.preventDefault()
    if (passwordLengthShouldbeGreatorthenfive() && doesPasswordMatch()) {
      
      onFormSubmit({
        name: name,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
      });
    }
  };
  return (
    <div>
      <form onSubmit={submitBuyerInfo} className="flex flex-col">
        <label
          className="text-xs leading-3 font-semibold text-gray-800 dark:text-gray-200 mt-8 mb-2"
          htmlFor=""
        >
          Enter Your Name
        </label>
        <input
          className="h-10 p-3 font-sans border-b-2 border-b-[#1C0A00] focus:outline-none"
          type="text"
          placeholder="Name"
          name="name"
          required
          onChange={(e) => setName(e.target.value)}
        />
        <label
          className="text-xs leading-3 font-semibold text-gray-800 dark:text-gray-200 mt-8 mb-2"
          htmlFor=""
        >
          Enter Your Email
        </label>
        <input
          className="h-10 p-3 font-sans border-b-2 border-b-[#1C0A00] focus:outline-none"
          type="tel"
          placeholder="Email"
          name="email"
          required
          onChange={(e) => setEmail(e.target.value)}
        />

        <label
          className="text-xs leading-3 font-semibold text-gray-800 dark:text-gray-200 mt-8 mb-2"
          htmlFor=""
        >
          Create a Password
        </label>
        <span className="relative">
          <input
            className={`h-10 p-3 font-sans  focus:outline-none ${
              doesPasswordMatch() || passwordLengthShouldbeGreatorthenfive()
                ? "border-b-2 border-b-red-600"
                : "border-b-2 border-b-[#1C0A00]"
            }`}
            type={`${showPassword ? "text" : "password"}`}
            placeholder="Password"
            name="password"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <span
            className="absolute top-1 right-1 cursor-pointer"
            onClick={() => setShowPassword((e) => !e)}
          >
            {showPassword ? (
              <EyeOffIcon className="h-5 w-5 mx-2 outline-2 outline-gray-500" />
            ) : (
              <EyeIcon className="h-5 w-5 mx-2 outline-1 outline-gray-500" />
            )}
          </span>
        </span>

        <label
          className="text-xs leading-3 font-semibold text-gray-800 dark:text-gray-200 mt-8 mb-2"
          htmlFor=""
        >
          Confirm Password
        </label>
        <span className="relative">
          <input
            className={`h-10 p-3 font-sans  focus:outline-none ${
              doesPasswordMatch()
                ? "border-b-2 border-b-red-600"
                : "border-b-2 border-b-[#1C0A00]"
            }`}
            type={`${showConfirmPassword ? "text" : "password"}`}
            name="confirmPassword"
            placeholder="Confirm Password"
            required
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <span
            className="absolute top-1 right-1 cursor-pointer"
            onClick={() => setshowConfirmPassword((e) => !e)}
          >
            {showConfirmPassword ? (
              <EyeOffIcon className="h-5 w-5 mx-2 outline-2 outline-gray-500" />
            ) : (
              <EyeIcon className="h-5 w-5 mx-2 outline-1 outline-gray-500" />
            )}
          </span>
        </span>
        <p hidden={doesPasswordMatch()} className="too-small text-red-600">
          Password do not match
        </p>
        <p
          hidden={passwordLengthShouldbeGreatorthenfive()}
          className="too-small text-red-600"
        >
          Password length should be greator than Five letters.
        </p>
        <span className="flex pt-4 m-auto items-center">
          <input className="m-2" type="checkbox" name="" id="" />
          <label className="too-small mt-0">
            I agree to the given terms and condition
          </label>
        </span>
        <button className="btn-invt mb-2">Sign Up</button>
      </form>
    </div>
  );
};

export default Buyer;
