import { ThumbDownIcon, ThumbUpIcon } from "@heroicons/react/outline";
import React from "react";

const Review = () => {
  return (
    <div className="border-2 shadow-sm p-3 mx-auto bg-gray-100">
      <p className="text-gray-600 text-sm">
        <strong>
          <a className="text-gray-800" href="">
            Pranay Prajapati{" "}
          </a>{" "}
        </strong>
        hat a reader will be distracted by the readable content of a page when
        looking at its layout. The point of using Lorem Ipsum is that it has a
        more-or-less normal distribution of letters, as opposed to using
        'Content here, content here'.
      </p>
      <span className="flex items-center text-sm font-medium py-2">
        Helpful? <ThumbDownIcon className="h-4 w-4  mx-2 outline-gray-600 cursor-pointer hover:fill-blue-400"/>
        <ThumbUpIcon className="h-4 w-4 outline-gray-600 cursor-pointer hover:fill-blue-400"/>
      </span>
    </div>
  );
};

export default Review;
