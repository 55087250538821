import { useState } from "react";
import { useNavigate } from "react-router-dom";

import OTP from "../OTP/OTP";
import Buyer from "./Buyer/Buyer";

const Signup = () => {
  const [userType, setuserType] = useState(null);
  const [showOtp, setshowOtp] = useState(false);
  const [showBuyerCredentials, setshowBuyerCredentials] = useState(null);
  const navigate = useNavigate();
  const handleRegisterationType = (UserType) => {
    setuserType(UserType);
    if (UserType === "seller") {
      navigate("/seller");
    } else setshowBuyerCredentials(true);
  };

  const [userCredentials, setuserCredentials] = useState();

  const onFormSubmit = (data) => {
    setuserCredentials(data);
    setshowBuyerCredentials(false);
    setshowOtp(true);
  };

  return (
    <div className=" px-4 py-4 flex flex-col items-center justify-evenly">
      {userType === null ? (
        <>
          <button
            onClick={() => handleRegisterationType("buyer")}
            className="btn"
          >
            Buyer
          </button>
          <button
            onClick={() => handleRegisterationType("seller")}
            className="btn-invt"
          >
            Seller
          </button>
        </>
      ) : null}

      {showBuyerCredentials ? <Buyer onFormSubmit={onFormSubmit} /> : null}
      {showOtp ? (
        <OTP userType={"customer"} userCredentials={userCredentials} />
      ) : null}
    </div>
  );
};

export default Signup;
