import React, { useState } from "react";
import OTP from "../Registeration/OTP/OTP";
const ForgetPassword = () => {
  const [LoginType, setLoginType] = useState("customer");
  return (
    <div className="w-10/12 md:w-1/2 lg:w-1/4  bg-gray-100 text-gray-800 text-center min-h-fit  m-auto mt-8 border shadow-sm border-[#868381] rounded-sm">
      <p className="bg-gray-700 w-full  text-gray-50 mb-6 p-2 font-normal text-lg">
        Forget Password
      </p>
      <span className="space-x-5 mx-auto pt-5 text-gray-800">
        <span>
          <input
            className="mt-1 appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-gray-700 checked:border-gray-700 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
            type="radio"
            name="loginType"
            id="Buyer"
            value={"customer"}
            checked={LoginType === "customer"}
            onChange={() => setLoginType("customer")}
          />
          Buyer
        </span>
        <span>
          <input
            onChange={() => setLoginType("seller")}
            className="mt-1 appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-gray-700 checked:border-gray-700 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
            type="radio"
            name="loginType"
            id="Seller"
            value="Seller"
            checked={LoginType === "seller"}
          />
          Seller
        </span>
      </span>
      <div className="px-5">
        <OTP ForgetPassword={true} userType={LoginType} />
      </div>
    </div>
  );
};

export default ForgetPassword;
