import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Login from "./Login/Login";
import OTP from "./OTP/OTP";
import Signup from "./Signup/Signup";

const Registeration = () => {
  let { sellerInfo } = useParams();
  const {state} = useLocation();
  const [selectBlock, setSelectBlock] = useState("login");
  return (
    <div className="w-10/12 md:w-1/2 lg:w-1/4  text-[#1C0A00] min-h-fit  m-auto mt-8 border shadow-sm border-[#868381] rounded-md">
      <div className="w-full p-2 bg-gray-800 text-gray-100">
        <button
          className={`border-r-[#0f0601] w-1/2 font-semibold p-1 hover:scale-105  ${
            selectBlock === "signup" ? "border-b-2 border-b-gray-200" : null
          }`}
          onClick={() => setSelectBlock("signup")}
        >
          Signup
        </button>
        <button
          className={` w-1/2 font-semibold hover:scale-105  p-1  ${
            selectBlock === "login" ? "border-b-2 border-b-gray-200" : null
          }`}
          onClick={() => setSelectBlock("login")}
        >
          Login
        </button>
      </div>
      {sellerInfo ? (
        <div className=" px-4 py-9 flex flex-col items-center justify-evenly">
          <OTP userType={"seller"} userCredentials={state} />
        </div>
      ) : (
        <>{selectBlock === "login" ? <Login /> : <Signup />}</>
      )}
    </div>
  );
};

export default Registeration;
