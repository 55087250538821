import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Notify from "../../../Utilities/ToastNotification/ToastNotification";
import Review from "./Review/Review";
import { user as UserAtom } from "../../../RecoilConfig";
import { useRecoilState, useRecoilValue } from "recoil";
import { cart as cartAtom } from "../../../RecoilConfig";
import { CheckIcon } from "@heroicons/react/outline";

const ProductDescription = () => {
  const user = useRecoilValue(UserAtom);
  const [data, setDate] = useState();
  const [reviews, setReviews] = useState();
  const [rating, setRating] = useState(0)
  const [reviewsText, setReviewsText] = useState();
  const [addToCart, setAddtoCart] = useState(false);

  const [cart, setCart] = useRecoilState(cartAtom);
  const [checkLogin, setcheckLogin] = useState(false);

  const { productID } = useParams();
  useEffect(() => {
    console.log("UE1 runnig")
    axios
      .get(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/products/${productID}`)
      .then((response) => {
        setDate(response.data.data);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    console.log("UE2 runnig")
    axios.get(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/reviews/product/${productID}`).then((response) => {
      setReviews(response.data.data);
    });
  }, []);

  const submitReviewHandler = () => {
    axios.post(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/reviews`, {
      sellerId: data?.seller,
      customerId: user.id,
      review: reviewsText,
      rating: rating,
      productID: productID
    });
  };

  const addToBasket = (ID) => {
    // dispatch data to data layer
    if (user) {
      updateCartinDatabase(ID, 1);
    } else {
      setcheckLogin(true);
      setTimeout(() => {
        setcheckLogin(false);
      }, 5000);
    }
  };

  const fetchCartFromDatabase = (cartID) => {
    axios
      .get(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/carts/${cartID}`)
      .then((response) => setCart(response.data.data.cartProducts));
  };

  const updateCartinDatabase = (productID, productQuantity) => {
    axios
      .post(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/carts/${user.cart}`, {
        cartProducts: {
          cartProductId: productID,
          cartProductQty: productQuantity,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setAddtoCart(true);
          fetchCartFromDatabase(user.cart);
        }
      })
      .catch((err) => console.log(err));
  };

  const isAddedtoCart = (productID) => {
    const index = cart.findIndex((ele) => ele.cartProductId._id === productID);
    return index >= 0;
  };

  return (
    <div>
      <h1 className="w-full py-3 mx-auto my-8 text-4xl text-center text-gray-800 border-b lg:mx-4">
        Product Description
      </h1>
      <div className="flex flex-col py-10 border-b lg:flex-row">
        <div className="w-full px-10 lg:w-1/2 lg:border-r">
          <img
            className="object-contain mx-auto rounded-md lg:w-2/3"
            src={data?.image}
            alt="product-image"
          />
        </div>
        <div className="w-full px-5 lg:w-1/2 lg:mx-5">
          <h1 className="py-2 text-3xl">
            {data?.name} - {data?.category}{" "}
          </h1>
          <p className="pb-4 text-sm text-gray-600">{data?.longDescription}</p>
          <p className="text-base">Price: {data?.price}</p>
          <p className="text-base">Stock: {data?.stock}</p>
          <div className="flex items-center justify-center w-full p-4 md:ml-0 md:w-1/4 md:flex-col">
            <span className="flex ml-10">
              {!isAddedtoCart(productID) && !addToCart ? (
                <>
                  <button
                    onClick={() => addToBasket(productID)}
                    className="px-4 md:px-4 py-2 border m-2 whitespace-nowrap border-gray-200 hover:border-2 hover:border-[#CC9544] "
                  >
                    Add to Cart
                  </button>
                  <p
                    hidden={!checkLogin}
                    className={
                      "too-small text-red-600 text-center ease-in-out duration-200"
                    }
                  >
                    Please login to start shopping.
                  </p>
                </>
              ) : (
                <button className="flex items-center px-4 py-2 m-2 border border-gray-200 md:px-4 whitespace-nowrap">
                  Added <CheckIcon className="w-6 h-6 ml-2 text-green-600" />{" "}
                </button>
              )}

              <button className="px-4 py-2 m-2 text-white bg-gray-800 border-2 border-gray-200 md:px-6 whitespace-nowrap hover:border-2 hover:border-gray-800 hover:bg-transparent hover:text-gray-800 ">
                Buy Now
              </button>
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full h-screen px-10 mt-10 lg:flex-row">
        <div className="w-full h-full overflow-y-scroll lg:w-1/2">
          <h1 className="text-3xl">Review</h1>
          <p className="pb-4 text-sm text-gray-600">
            Review helps us to decide the real world ussage of the product.
          </p>
          <div className="px-4 mx-auto space-y-3">
            <Review />
            <Review />
            <Review />
            <Review />
            <Review />
          </div>
        </div>
        <div className="w-full px-5 lg:w-1/2">
          <h1 className="text-3xl">Add your review</h1>
          <div className="w-full">
            <textarea
              onChange={(e) => setReviewsText(e.target.value)}
              name=""
              className="w-full mt-5 border border-gray-400 rounded-sm focus:outline-none"
              placeholder="Write Your Review"
              rows={4}
            ></textarea>
            <button
              onClick={submitReviewHandler}
              className="mr-2 text-right btn-invt"
            >
              Submit Review
            </button>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDescription;
