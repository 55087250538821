import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import slider1 from "../../Utilities/Assets/Images/james-baltz-yihX4Rq-JsI-unsplash.jpg"
import slider2 from "../../Utilities/Assets/Images/rod-long-J-ygvQbilXU-unsplash.jpg"
import slider3 from "../../Utilities/Assets/Images/steven-weeks-DUPFowqI6oI-unsplash.jpg"

const Slider = () => {
  const navigate = useNavigate();
  return (
    <div className="h-full md:h-72 overflow-hidden object-center mx-auto">
      <Carousel
        autoPlay
        infiniteLoop
        interval={2000}
        showThumbs={false}
        showStatus={false}
        centerMode
        centerSlidePercentage={100}
      >
        <div onClick={() => navigate("government-information")}>
          <img
            className="object-cover cursor-pointer"
            alt=""
            src={slider1}
          />
        </div>
        <div>
          <img
            className="object-cover cursor-pointer"
            alt=""
            src={slider2}
          />
        </div>
        <div>
          <img
            className="object-cover"
            alt=""
            src={slider3}
          />
        </div>
        {/* <div>
          <img
            className="object-cover"
            alt=""
            src="https://images.unsplash.com/photo-1601388352547-2802c6f32eb8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1363&q=80"
          />
        </div> */}
      </Carousel>
    </div>
  );
};

export default Slider;
