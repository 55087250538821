import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { user as UserAtom } from "../../../RecoilConfig";
import Notify from "../../../Utilities/ToastNotification/ToastNotification";

const Orders = () => {
  const [orders, setorders] = useState();
  const user = useRecoilValue(UserAtom);
  useEffect(() => {
    axios.get(`/api/v1/orders/${user.id}`).then((response) => {
      Notify(response.data.message)
      console.log(response);
    });
  }, []);
  return (
    <div>
      <h1>Orders</h1>
    </div>
  );
};

export default Orders;
