import React from "react";
import { user as userAtom, cart as cartAtom } from "../../RecoilConfig";
import { useRecoilValue ,useRecoilState } from "recoil";
import CheckOutProduct from "./CheckOutProduct/CheckOutProduct";
import Subtotal from "./Subtotal/Subtotal";
import { useEffect } from "react";
import axios from "axios";

function Cart() {
  const user = useRecoilValue(userAtom)
  const [cart,setCart] = useRecoilState(cartAtom)
  const fetchCartFromDatabase = (cartID) => {
    axios
      .get(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/carts/${cartID}`)
      .then((response) => setCart(response.data.data.cartProducts));
  };
  useEffect(() => {
    fetchCartFromDatabase(user.cart)
  }, [])
  return (
    <div className={" ml-5 p-3"}>
      <h2 className={"text-3xl font-sans font-semibold text-[#1C0A00]"}>
        Hey, {user ? user.name : "Guest"}
      </h2>
      <h2 className={"text-lg font-normal text-gray-400"}>
        I am Your Shopping basket
      </h2>

      <div className="flex flex-col-reverse m-auto md:flex-row">
        {cart.length === 0 ? (
          <div className="flex flex-col items-center w-full py-8 mt-8 bg-gray-200 rounded-md animate-pulse md:w-2/3 md:flex-row justify-evenly">
            <div className="w-24 h-24 m-10 bg-white rounded-full"></div>
            <div className="mt-5 space-y-2">
              <div className="h-6 bg-white rounded-sm w-60 md:w-36 lg:w-60"></div>
              <div className="h-6 bg-white rounded-sm w-60 md:w-36 lg:w-60"></div>
              <div className="h-6 bg-white rounded-sm w-60 md:w-36 lg:w-60"></div>
              <div className="h-6 bg-white rounded-sm w-60 md:w-36 lg:w-60"></div>
            </div>
            <div className="mt-5 space-y-2">
              <div className="h-6 bg-white rounded-sm w-60 md:w-36 lg:w-60"></div>
              <div className="h-6 bg-white rounded-sm w-60 md:w-36 lg:w-60"></div>
              <div className="h-6 bg-white rounded-sm w-60 md:w-36 lg:w-60"></div>
              <div className="h-6 bg-white rounded-sm w-60 md:w-36 lg:w-60"></div>
            </div>
          </div>
        ) : (
          <div className={"w-full md:w-2/3"}>
            <div>
              {cart?.map((item,i) => {
                return (
                  <CheckOutProduct
                    id={item.cartProductId._id}
                    key={item.cartProductId._id}
                    name={item.cartProductId.name}
                    image={item.cartProductId.image}
                    rating={item.cartProductId.rating}
                    price={item.cartProductId.price}
                    description={item.cartProductId.longDescription}
                    quantity={item.cartProductQty}
                  />
                );
              })}
            </div>
          </div>
        )}

        <div className={""}>
          <Subtotal />
        </div>
      </div>
    </div> 





  );
}

export default Cart;
