import React from "react";
import { useNavigate } from "react-router-dom";
import { BadgeCheckIcon, XIcon } from "@heroicons/react/solid";

const OTPResponse = ({ message, description }) => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-40 flex flex-col justify-center items-center text-center">
      {message === "SUCCESS" ? (
        <BadgeCheckIcon className="w-14 h-14 fill-green-600 " />
      ) : (
        <XIcon className="w-14 h-14 fill-red-600 " />
      )}
      <h1 className="font-semibold text-lg">{message}</h1>
      <p className="text-gray-400 text-xs font-sans font-normal p-2 mb-3">
        {description}
      </p>
      {message === "SUCCESS" ? (
        <p className="text-gray-500 text-xs font-sans font-normal p-2 mb-3">
          Redirecting you in 5 seconds
        </p>
      ) : (
        <p
          onClick={() => navigate("/")}
          className="text-gray-500 cursor-pointer text-xs font-sans font-normal p-2 mb-3 hover:font-semibold"
        >
          Click here to try again.
        </p>
      )}
    </div>
  );
};

export default OTPResponse;
