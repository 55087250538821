import React from "react";
import { useNavigate } from "react-router-dom";

const Card = ({ name, description, image, subCategories }) => {
  const navigate = useNavigate();

  const SelectCategory = () => {
    navigate(`/subcategory`, { state: subCategories });
  };
  return (
    <div
      onClick={SelectCategory}
      className="w-full md:w-1/4 lg:h-1/5 m-3 bg-white shadow-lg rounded-md ease-in-out duration-300 overflow-hidden cursor-pointer border-2 border-gray-200 hover:scale-105"
    >
      <div className="h-60  overflow-hidden object-cover">
        <img className="object-cover w-full h-full" src={image} alt="" />
      </div>
      <div className="py-3 px-1">
        <h3 className=" mt-2 font-semibold text-[#1C0A00] text-lg">{name}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
};

export default Card;
