import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import Card from "./Card/Card";

const GovernmentInformation = () => {
  const [data, setData] = useState();
  const [tab,setTab] = useState()
  useEffect(() => {
    axios.get("https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/wiki").then((response) => setData(response.data.data));
  }, []);

  const tabHandler = (name) =>{
    if(tab === name)
        setTab(null)
    else  
        setTab(name)
  }
  return (
    <div className="w-full ">
      <h1 className="text-4xl text-green-800 text-center py-5 border-b font-semibold">Goverment Information</h1>
      <div className="md:h-screen flex flex-col md:flex-row justify-evenly mx-auto md:w-11/12 mt-10">
        {data?.map((item) => (
          <div  key={item._id} className="w-full md:1/3 px-2 mt-4">
            <h2 onClick={() => tabHandler(item.name)} className="text-center text-2xl text-green-700 bg-gray-200 mb-5 rounded-sm p-1 font-normal border border-l-green-600 hover:text-green-900 cursor-pointer">{item.name}</h2>
            <div hidden={tab !== item.name} className="w-full overflow-x-hidden md:h-screen md:overflow-y-scroll">
              {item.files.map((file) => (
                <Card key={file.id} fileName={file.fileName} link={file.link} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GovernmentInformation;
