import axios from "axios";
import { Result } from "postcss";
import React, { Fragment, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'
import { user as userAtom, cart as cartAtom } from "../../../RecoilConfig";
import Products from "../../Products/Products";

function Subtotal() {
  const navigate = useNavigate();
  const [Price, setPrice] = useState();
  const user = useRecoilValue(userAtom);
  const cart = useRecoilValue(cartAtom);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    let totalPrice = 0;
    cart?.forEach((item) => {
      totalPrice = totalPrice + +item.cartTotalPrice;
      // console.log(item);
    });
    setPrice(totalPrice);
  }, [cart]);

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const navigateToProducts = () => {
    navigate('/products')
  }

  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus('Geolocation is not supported by your browser');
    } else {
      setStatus('Locating...');
      navigator.geolocation.getCurrentPosition((position) => {
        setStatus(null);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      }, () => {
        setStatus('Unable to retrieve your location');
      });
    }
  }

  // razorpay integration
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    getLocation();
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post("https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/payment/initiate-payment", { amount: Price });

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }
    var user = JSON.parse(localStorage.getItem('user'));
    // Getting the order details back
    const { amount, id: order_id, currency } = result.data.data;

    const options = {
      key: "rzp_test_tTrv1FSd6N1liG", // Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: user.name,
      description: "Test Transaction",
      // image: { logo },
      order_id: order_id,
      handler: async function (response) {
        console.log(response);
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        const result = await axios.post("https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/payment/verify-payment", data);

        console.log(result.data.message);
        if (result.data.success) {
          // console.log(user);
          openModal();
          const orderItems = [];
          cart?.forEach((item) => {
            orderItems.push({
              productId: item.cartProductId._id,
              quantity: item.cartProductQty
            })
          });
          const order = await axios.post("https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/orders", {
            customerId: user.id,
            phoneNo: user.username,
            email: user.email,
            address: {
              houseNumber: "",
              floor: "",
              landmark: "",
              street: "",
              city: "",
              state: "",
              pincode: "",
              latitude: lat,
              longitude: lng,
            },
            items: orderItems,
            paymentMethod: "UPI",
            paymentStatus: "COMPLETED"
          });
          console.log(isOpen)
        }
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.username,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#61dafb",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <>
      <div
        className={
          "border border-[#1C0A00] text-[#1C0A00] p-4 mt-4 mx-3 rounded-md"
        }
      >
        <p className="text-lg">
          Subtotal ({cart?.length} items) :<strong> ₹ {Price}</strong>
        </p>
        <small className={""}>
          <input type={"checkbox"} />
          This Order Contains a gift.
        </small>
        <br />

        <button className="btn" type="submit" onClick={displayRazorpay}>
          Proceed to Checkout
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 text-gray-900"
                  >
                    Payment successful
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-gray-500">
                      Your payment has been successfully submitted. We're processing your order now. Thank you for shopping with us.
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                    <button
                      type="button"
                      className="inline-flex ml-5 justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={navigateToProducts}
                    >
                      Continue Shopping
                    </button>

                    <Routes>
                      <Route path="/products" element={<Products />} />
                    </Routes>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default Subtotal;
