import React from 'react'

const DefaultRoute = () => {
  return (
    <div>
        <h1 className='text-3xl text-gray-600 text-center'>No such route buddy!!</h1>
    </div>
  )
}

export default DefaultRoute