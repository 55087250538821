import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Categories from "./Components/Categories/Categories";
import Products from "./Components/Products/Products";
import { Route, Routes } from "react-router-dom";
import Registeration from "./Components/Registeration/Registeration";
import Buyer from "./Components/Registeration/Signup/Buyer/Buyer";
import Seller from "./Components/Registeration/Signup/Seller/Seller";
import SubCategories from "./Components/SubCategories/SubCategories";
import Aboutus from "./Components/Aboutus/Aboutus";
import Contact from "./Components/Contact/Contact";
import Cart from "./Components/Cart/Cart";
import Payment from "./Components/Payment/Payment";
import ForgetPassword from "./Components/ForgetPassword/ForgetPassword";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { user as userAtom, cart as CartAtom } from "./RecoilConfig";
import { ProtectedRoute } from "./Utilities/ProtectedRoutes";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import Dashboard from "./Components/SellerDashboard/SellerDashboard";
import DefaultRoute from "./Utilities/ErrorHandler/DefaultRoute";
import ProductDescription from "./Components/Products/ProductDescription/ProductDescription";
import GovernmentInformation from "./Components/GovernmentInformation/GovernmentInformation";
function App() {
  const [user, setUser] = useRecoilState(userAtom);
  const [cart, setCart] = useRecoilState(CartAtom);

  const fetchCartFromDatabase = (cartID) => {
    axios
      .get(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/carts/${cartID}`)
      .then((response) => setCart(response.data.data.cartProducts));
  };
  useEffect(() => {
    // if user has any previous login revoke that session
    // if there is a previous session it gotta be in the localstorage to checking it out and performing login
    if (localStorage.getItem("user")) {
      setUser(JSON.parse(localStorage.getItem("user")));
      if (localStorage.getItem("cart")) {
        fetchCartFromDatabase(JSON.parse(localStorage.getItem("cart")));
      }
    } else {
      // do some shit cuz there is no user logged in
      setUser(null);
    }
  }, []);

  return (
    <div className="h-screen max-w-full overflow-x-hidden bg-white">
      <ToastContainer />
      <header className="sticky top-0 z-50">
        <Navbar />
      </header>

      <Routes>
        <Route path="/" element={<Categories />} />
        <Route path="/products/items/:productID" element={<ProductDescription />} />
        <Route path="/products/" element={<Products />} />
        <Route path="/subcategory" element={<SubCategories />} />
        <Route path="/registeration" element={<Registeration />} />
        <Route
          path="/registeration/api/forget-password"
          element={<ForgetPassword />}
        />
        <Route path="/registeration/:sellerInfo" element={<Registeration />} />
        <Route path="/buyer" element={<Buyer />} />
        <Route path="/seller" element={<Seller />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/government-information" element={<GovernmentInformation />} />
        <Route
          path="/cart"
          element={
            <ProtectedRoute isAllowed={user}>
              <Cart />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute isAllowed={user}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/payment" element={<Payment />} />
        <Route path={"*"} element={<DefaultRoute />} />
      </Routes>
    </div>
  );
}

export default App;
