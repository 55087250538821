import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { user as userAtom, cart as CartAtom } from "../../../RecoilConfig";
import Notify from "../../../Utilities/ToastNotification/ToastNotification";
const Login = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const [cart, setCart] = useRecoilState(CartAtom);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setpassword] = useState("");
  const [LoginType, setLoginType] = useState("customer");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const fetchCartFromDatabase = (cartID) => {
    axios
      .get(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/carts/${cartID}`)
      .then((response) => setCart(response.data.data.cartProducts));
  };

  const loginHandler = (event) => {
    event.preventDefault();
    axios
      .post(`https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/auth/${LoginType}/login`, {
        phoneNo: phoneNumber,
        password: password,
      })
      .then((response) => {
        if (response.data.success) {
          console.log(response);
          let User = {
            id: response.data.data[`${LoginType}Data`]._id,
            username: response.data.data[`${LoginType}Data`].phoneNo,
            name: response.data.data[`${LoginType}Data`].name,
            email: response.data.data[`${LoginType}Data`].email,
            cart: response.data.data[`${LoginType}Data`]?.cart,
            userType: LoginType,
          };
          setUser(User);
          localStorage.setItem("user", JSON.stringify(User));
          if (LoginType === "customer") {
            localStorage.setItem("cart", JSON.stringify(User.cart));
            fetchCartFromDatabase(User.cart);
          }
          Notify(response.data.message)
          navigate("/");
        } else Notify(response.data.message);
      })
      .catch((err) => {
        Notify("Wrong Credentials");
      });
  };

  return (
    <div className="">
      <form onSubmit={loginHandler} className="flex flex-col px-4 py-9">
        <label className="font-medium text-sm text-[#1C0A00] py-2" htmlFor="">
          Phone Number
        </label>
        <input
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="h-10 p-3 font-sans bg-transparent border-b-2 rounded-sm border-b-gray-600 focus:outline-none "
          type="text"
          autoComplete="off"
          placeholder="Phone Number"
          required
          name=""
          maxLength={10}
        />

        <label
          className="font-medium text-sm text-[#1C0A00] mt-8 py-1  mb-3"
          htmlFor=""
        >
          Password
        </label>
        <span className="relative">
          <input
            onChange={(e) => setpassword(e.target.value)}
            className="relative w-full h-10 p-3 font-sans bg-transparent border-b-2 rounded-sm border-b-gray-600 focus:outline-none "
            type={`${showPassword ? "text" : "password"}`}
            placeholder="Password"
            required
            name=""
          />
          <span
            className="absolute z-20 cursor-pointer top-2 right-2"
            onClick={() => setShowPassword((e) => !e)}
          >
            {showPassword ? (
              <EyeOffIcon className="w-5 h-5 outline-2 outline-gray-500" />
            ) : (
              <EyeIcon className="w-5 h-5 outline-2 outline-gray-500" />
            )}
          </span>
        </span>
        <span className="pt-5 m-auto space-x-5">
          <span>
            <input
              className="w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-full appearance-none cursor-pointer checked:bg-gray-700 checked:border-gray-700 focus:outline-none"
              type="radio"
              name="loginType"
              id="Buyer"
              value={"customer"}
              checked={LoginType === "customer"}
              onChange={() => setLoginType("customer")}
            />
            Buyer
          </span>
          <span>
            <input
              onChange={() => setLoginType("seller")}
              className="w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-full appearance-none cursor-pointer checked:bg-gray-700 checked:border-gray-700 focus:outline-none"
              type="radio"
              name="loginType"
              id="Seller"
              value="Seller"
              checked={LoginType === "seller"}
            />
            Seller
          </span>
        </span>
        <button onClick={loginHandler} className="btn-invt">
          Login
        </button>
        <button
          onClick={() => navigate("api/forget-password")}
          className="my-3 text-sm font-semibold text-gray-800 hover:scale-105"
        >
          Forgot Password
        </button>
      </form>
    </div>
  );
};

export default Login;
