import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { user as UserAtom } from "../../../RecoilConfig";
import { useRecoilValue } from "recoil";
import Notify from "../../../Utilities/ToastNotification/ToastNotification";
import Items from "./Items/Items";
const Products = () => {
  const user = useRecoilValue(UserAtom);
  const [productsOftheSeller, setproductsOftheSeller] = useState(null);

  const [title, setTitle] = useState();
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState();
  const [price, setPrice] = useState();
  const [stock, setStock] = useState();
  const [description, setDescription] = useState();
  const [data, setData] = useState([]);

  const addProduct = (e) => {
    e.preventDefault();
    axios
      .post(`/api/v1/products`, {
        name: title,
        seller: user.id,
        longDescription: description,
        category: category.value,
        subCategory: subCategory,
        price: price,
        stock: stock,
      })
      .then((response) => {
        Notify(response.data.message);
        setproductsOftheSeller(null)
      })
      .catch((err) => {
        console.log(err);
        Notify(err.message);
      });
  };

  useEffect(() => {
    axios
      .get(`/api/v1/products/seller/${user.id}`)
      .then((response) => {
        setproductsOftheSeller(response.data.data);
      })
      .catch((err) => console.log(err));

    axios
      .get("/api/v1/categories")
      .then((response) => {
        console.log(response);
        setData(response.data.data);
      })
      .catch((err) => console.log(err));
  }, [productsOftheSeller]);

  const isFormValid = () => title && category && price && stock && description;
  return (
    <>
      <h1 className="text-center text-4xl my-5 w-full">Product</h1>
      <div className="flex flex-col lg:flex-row justify-evenly w-full mx-auto ">
        <div className="w-full lg:w-5/12 mx-auto lg:mx-4 py-5 border-b lg:border-r ">
          <h2 className="text-center text-2xl text-gray-700 ">
            Add a new Product
          </h2>
          <form className="flex flex-col py-3 px-5 space-y-3" action="">
            <label className="too-small " htmlFor="">
              Title Of the product
            </label>
            <input
              onChange={(e) => setTitle(e.target.value)}
              className="inpt-seller mb-3"
              type="text"
              name=""
              placeholder="Name of Product"
            />

            <label className="too-small " htmlFor="">
              Category
            </label>
            <select
              onChange={(e) =>
                setCategory({
                  id: +e.target.selectedIndex - 1,
                  value: e.target.value,
                })
              }
              className="inpt-seller mb-3"
              type="text"
              name=""
              placeholder="Category"
            >
              <option value="">Select Category</option>
              {data.map((item, i) => (
                <option id={i} value={item.name} key={i}>
                  {item.name}
                </option>
              ))}
            </select>
            {category ? (
              <>
                <label className="too-small " htmlFor="">
                  Sub Category
                </label>
                <select
                  onChange={(e) => setSubCategory(e.target.value)}
                  className="inpt-seller mb-3"
                  type="text"
                  name=""
                  placeholder="Sub-Category"
                >
                  <option value="">Select Sub-Category</option>
                  {data[category.id]["subCategories"].map((item, i) => (
                    <option id={i} value={item.name} key={i}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </>
            ) : null}
            <label className="too-small " htmlFor="">
              Price of Each
            </label>
            <input
              onChange={(e) => setPrice(e.target.value)}
              className="inpt-seller mb-3"
              type="number"
              placeholder="Price"
              min={0}
            />
            <label className="too-small " htmlFor="">
              Number of Items in Stock
            </label>
            <input
              onChange={(e) => setStock(e.target.value)}
              className="inpt-seller mb-3"
              type="number"
              placeholder="Stock"
              min={0}
            />
            <label className="too-small " htmlFor="">
              Description
            </label>
            <textarea
              onChange={(e) => setDescription(e.target.value)}
              className="inpt-seller mb-3"
              type="text"
              placeholder="Description"
              name=""
              cols="30"
              rows="3"
            ></textarea>
            <p className="too-small text-red-700">All fields are Mandatory</p>
            <button
              onClick={addProduct}
              disabled={!isFormValid()}
              className={`btn-invt mt-4 ${
                isFormValid() ? "cursor-pointer" : "cursor-not-allowed"
              }`}
            >
              Add
            </button>
          </form>
        </div>
        <div className="w-full lg:w-7/12 mx-auto h-screen overflow-y-scroll">
          <h2 className="text-center text-2xl text-gray-700 ">Product List</h2>
          {productsOftheSeller?.map((item) => (
            <Items
              key={item._id}
              name={item.name}
              description={item.longDescription}
              image={item.image}
              stock={item.stock}
              price={item.price}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Products;

// category: "Dairy Product"
// createdAt: "2022-06-18T14:36:23.579Z"
// image: "https://static.toiimg.com/photo/msid-70139351/70139351.jpg"
// isDeleted: false
// longDescription: "Milk is a nutrient-rich liquid food produced by the mammary glands of mammals. It is the primary source of nutrition for young mammals before they are able to digest solid food."
// name: "Milk"
// price: 24
// seller: "629df16b0c59270e9806df77"
// shortDescription: " "
// stock: 1222
// subCategory: ""
// updatedAt: "2022-06-18T14:36:23.579Z"
