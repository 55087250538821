import {atom} from "recoil"

export const user = atom({
    key:"user",
    default:null
})

export const cart = atom({
    key:"cart",
    default:[],
    dangerouslyAllowMutability:true
})

export const products = atom({
    key:"products",
    default:[],
    dangerouslyAllowMutability:true
})
