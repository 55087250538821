import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import axios from "axios";
import React, { useState } from "react";

const OTPForm = ({ submit, phonenumber }) => {
  const [OTPDigit, setOTPDigit] = useState(null);
  const [disableResendOTPButton, setdisableResendOTPButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const verifyOTP = (e) => {
    axios
      .post("https://beasenestsupplier-backend-v1-dev-6lzzbejqfa-el.a.run.app/api/v1/auth/customer/verify-otp", {
        phoneNo: phonenumber,
        code: OTPDigit,
      })
      .then((response) => {
        if (response.data.success) {
          submit("SUCCESS");
        } else submit("FAILED");
      })

      .catch((err) => {
        submit("FAILED");
        console.log(err);
      });
    e.preventDefault();
  };

  const reSendOTPHandler = () => {
    setdisableResendOTPButton(true);
    setTimeout(() => {
      setdisableResendOTPButton(false);
    }, 60000);
    axios
      .post("api/v1/auth/customer/send-otp", { phoneNo: phonenumber })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
        }
      })
      .catch((err) => alert("An error occured"));
  };
  return (
    <div className="container mx-auto">
      <div className="mx-auto ">
        <div className="w-full">
          <div className="p-3 text-center rounded ">
            <h1 className="text-2xl font-bold">OTP Verification</h1>
            <div className="flex flex-col mt-4">
              <span>Enter the OTP you received at</span>
              <span className="font-bold">+91 {phonenumber}</span>
            </div>

            <form id="otp">
              <div className="flex flex-row items-center justify-center px-3 mt-5 text-center">
                <input
                  className="w-full h-10 m-2 text-center border rounded form-control"
                  type={`${showPassword ? "text" : "password"}`}
                  id="first"
                  maxLength="4"
                  autoComplete="off"
                  onChange={(e) => setOTPDigit(e.target.value)}
                />
                <span
                  className="z-20 cursor-pointer  top-2 right-2"
                  onClick={() => setShowPassword((e) => !e)}
                >
                  {showPassword ? (
                    <EyeOffIcon className="w-5 h-5 outline-2 outline-gray-500" />
                  ) : (
                    <EyeIcon className="w-5 h-5 outline-2 outline-gray-500" />
                  )}
                </span>
              </div>

              <button onClick={(e) => verifyOTP(e)} className="btn-invt">
                Submit
              </button>
            </form>
            <button
              disabled={disableResendOTPButton}
              onClick={() => reSendOTPHandler()}
              className="py-2 text-sm cursor-pointer hover:font-semibold"
            >
              Resend OTP
            </button>
            <p hidden={!disableResendOTPButton} className="too-small">
              You can request for OTP every 1 Min.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPForm;
